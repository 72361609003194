<script setup>
	import { defineProps, defineEmits, ref, watch, computed } from 'vue'
    import Chat from '../Chat.vue'
    import Spinner from '../Spinner.vue'
    import NetworkMembers from '../NetworkMembers.vue'
    import axios from "axios"
    import { vMaska } from "maska"

	const props = defineProps({
		question: {
			type: Object,
			required: true
		}
	})
    const emit = defineEmits(['proceed','hideBackButton','showBackButton'])

    const displayValidation = ref(false)
    const dayInvalid = ref(false)
    const monthInvalid = ref(false)
    const yearInvalid = ref(false)
    const validating = ref(false)

    watch(validating, async (value) => {
        if (value) {
            emit('hideBackButton')
        } else {
            emit('showBackButton')
        }
    })

    function checkDay(e) {
        if (isFinite(e.key) && props.question.dayMaskaObject.completed) {
            document.getElementById('year').focus()
        }
        dayInvalid.value = false
        displayValidation.value = false
    }
    function dayKeydown(e) {
        // console.log(e)
        // e.which === 9
        if (e.which === 9) {
            if (props.question.day.toString().length !== 2) {
                e.preventDefault()
                return false
            }
        } else if (isFinite(e.key)) { // a number was entered
            if (props.question.day.toString().length === 0) {
                // first number entered
                if (Number(e.key) >= 4) {
                    // add a zero and move to month field
                    e.preventDefault()
                    props.question.day = "0" + e.key.toString()
                    document.getElementById('year').focus()
                }
            } else {
                // second number entered
                // if ( Number(props.question.day.toString() + e.key.toString()) > 31 ) {
                //     e.preventDefault()
                // }
            }
        }
    }
    function checkMonth(e) {
        if (isFinite(e.key) && props.question.monthMaskaObject.completed) {
            document.getElementById('day').focus()
        }
        monthInvalid.value = false
        displayValidation.value = false
    }
    function monthKeydown(e) {
        // console.log(e)
        // e.which === 9
        if (e.which === 9) {
            if (props.question.month.toString().length !== 2) {
                e.preventDefault()
                return false
            }
        } else if (isFinite(e.key)) { // a number was entered
            if (props.question.month.toString().length === 0) {
                // first number entered
                if (Number(e.key) >= 2) {
                    // add a zero and move to year field
                    e.preventDefault()
                    props.question.month = "0" + e.key.toString()
                    document.getElementById('day').focus()
                }
            } else {
                // second number entered
                // if ( Number(props.question.month.toString() + e.key.toString()) > 12 ) {
                //     e.preventDefault()
                // }
            }
        }
    }
    function yearKeydown(e) {
        // console.log(e)
        if (isFinite(e.key)) { // a number was entered
            if (props.question.year.toString().length === 0) {
                // first number entered
                if (Number(e.key) > 2) {
                    e.preventDefault()
                }
            }
        }
    }
    function yearKeyup(e) {
        if (isFinite(e.key)) { // a number was entered
            if (props.question.year.toString().length === 4) {
                // console.log('setting valid')
                yearInvalid.value = false
                displayValidation.value = false
            }
        }
    }
    function checkDate(e) {
        // if day and month are single digit, add leading zero
        if (props.question.month.toString().length === 1) {
            props.question.month = "0" + props.question.month
        }
        if (props.question.day.toString().length === 1) {
            props.question.day = "0" + props.question.day
        }

        let currentYear = Number(new Date().getFullYear())
        if ( (Number(props.question.year) < (currentYear - 15)) && (Number(props.question.year) > (currentYear - 120)) ) {
            // emit('proceed')
        } else {
            yearInvalid.value = true
            displayValidation.value = true
        }

        if ( (Number(props.question.month) > 0) && (Number(props.question.month) < 13) ) {
            // emit('proceed')
        } else {
            monthInvalid.value = true
            displayValidation.value = true
        }

        if ( (Number(props.question.day) > 0) && (Number(props.question.day) < 32) ) {
            // emit('proceed')
        } else {
            dayInvalid.value = true
            displayValidation.value = true
        }

        if (displayValidation.value) {
            e.preventDefault()
            return false
        }

        emit('proceed')
    }

</script>

<template>
    <Spinner v-if="validating" :messages="['Validating your information...','Reviewing your option...','Checking to see how much you can save...']" />
    <section v-else class="btn-options" :class="{'was-validated' : displayValidation}">
        <h3 class="questionHeading">What’s your date of birth?</h3>

        <div class="dob-container mt-4">
            <div class="mb-3 dob-input dob-input-day">
                <label for="month">Month</label>
                <input
                    v-maska="question.monthMaskaObject"
                    data-maska="##"
                    class="form-control"
                    :class="{'is-invalid': monthInvalid}"
                    id="month"
                    placeholder="MM"
                    required
                    v-model="question.month"
                    @keyup="checkMonth"
                    @keydown="monthKeydown"
                    @keydown.enter="checkDate">
            </div>
            <div class="mb-3 dob-input dob-input-day">
                <label for="day">Day</label>
                <input
                    v-maska="question.dayMaskaObject"
                    data-maska="##"
                    class="form-control"
                    :class="{'is-invalid': dayInvalid}"
                    id="day"
                    placeholder="DD"
                    required
                    v-model="question.day"
                    @keyup="checkDay"
                    @keydown="dayKeydown"
                    @keydown.enter="checkDate">
            </div>
            <div class="mb-3 dob-input dob-input-day">
                <label for="year">Year</label>
                <input
                    v-maska="question.yearMaskaObject"
                    data-maska="####"
                    class="form-control"
                    :class="{'is-invalid': yearInvalid}"
                    id="year"
                    placeholder="YYYY"
                    required
                    v-model="question.year"
                    @keydown="yearKeydown"
                    @keyup="yearKeyup"
                    @keydown.enter="checkDate">
            </div>
        </div>

        <div class="invalid-feedback-custom" :class="{ show: displayValidation }">Please enter a valid date of birth</div>

        <button class="btn btn-primary" :disabled="!question.validated" @click="checkDate">Go To Final Step <i class="fa-solid fa-arrow-right" style="color: #ffffff;"></i></button>
        <!-- <p class="disclaimer">I agree that clicking the button above, I'm giving written instructions allowing Array US Inc to obtain info from my credit report for a soft credit check which will have no impact on my credit score. I understand that this info will be collected and shared with Array US Inc and First Advantage Financial LLC by a consumer reporting company.</p> -->
        <div v-if="question.chat">
            <Chat :message="question.chat" />
        </div>
    </section>

</template>

<style scoped>
    .btn-options {
        min-height: 65vh;
        position: relative;
    }
    .questionHeading {
        font-size: 1.6em;
        font-weight: 700;
        color: #2a2a2b;
        text-align: center;
        line-height: 1.2;
        margin-bottom: 35px;
    }

    .questionPreHeading {
        color: #19329a;
        font-style: italic;
        font-size: .9em;
        font-weight: 600;
        text-align: center;
        margin-bottom: 5px;
    }

    .questionSubHeading {
        font-size: 1em;
        text-align: center;
        margin-bottom: 5px;
    }

    .dob-container {
        display: flex;
        flex-wrap: no-wrap;
        justify-content: space-between;
    }

    .dob-input {
        width: 32%;
        width: calc(33% - 5px);
    }

    .btn-primary {
        background-color: #0D496F;
        border-color: #0D496F;
        padding: 20px 42px;
        font-size: 1.4em;
        font-weight: 700;
        border-radius: 39px;
        /* position: absolute;
        right: 0;
        bottom: 22px; */
        transition: all .3s ease;
        box-shadow: 0 10px 15px #00ACEF4d;
        display: block;
        width: 100%;
        margin-top: 40px;
    }

    .btn-primary:disabled {
        /* background-color: #ddd;
        border-color: #efefef; */
        box-shadow: none;
    }

    .form-control {
        border-color: #ccc;
        border-radius: 3px;
        box-shadow: 0 3px 0 #dbdBE866;
        position: relative;
        padding-left: 18px;
        height: 65px;
        font-size: 1.2em;
    }

    .dob-input>label {
        padding-left: 0px;
        color: #555;
        font-size: .95em;
    }

    .form-floating>.form-control:focus~label,
    .form-floating>.form-control:not(:placeholder-shown)~label {
        transform: scale(.75) translateY(-.5rem) translateX(.25rem);
    }

    .form-control:active,
    .form-control:focus,
    .form-control:hover {
        border-width: 2px;
        border-color: #f9892a;
        border-style: solid;
    }

    .disclaimer {
        font-size: .6em;
        line-height: 1.5;
        text-align: center;
        margin-top: 30px;
    }

    .linkText {
        color: hsla(160, 100%, 37%, 1);
        cursor: pointer;
    }

    .invalid-feedback-custom {
        display: none;
        color: red;
    }

    .invalid-feedback-custom.show {
        display: block !important;
    }

    @media (min-width: 551px) {
        
    }
</style>