import axios from 'axios'

function sendToEzsend(leadData) {
    const isoString = new Date().toISOString()
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://ygmydfoals2jitxzm7idwfdsca0kxlja.lambda-url.us-east-2.on.aws/ ',
        headers: { 
            'Content-Type': 'application/json'
        },
        data : JSON.stringify({
            "phone_mobile": "+1" + leadData.phone_home,
            "first_name": leadData.first_name,
            "last_name": leadData.last_name,
            "sms_optin": true,
            "sms_signup_ip": leadData.ip_address,
            "sms_signup_url": "https://firstadvantage.io/app/",
            "sms_signup_tstamp": isoString,
            "country": "US",
            "region": leadData.state
        })
    };

    axios.request(config).then((response) => {
        console.log(response.data);
    }).catch((error) => {
        console.log(error);
    });
}

export { sendToEzsend }