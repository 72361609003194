<script setup>
	import { computed, defineProps } from 'vue'
    import { advertiserMapping } from '../constants/selectOptions.js'

	const props = defineProps({
		advertiser: {
			type: String
		}
	})

    const textString = computed(() => {
        if (advertiserMapping[props.advertiser]) {
            if (advertiserMapping[props.advertiser].did !== null) {
                return `
                    <p>You don't need to be overwhelmed by your debt. ${advertiserMapping[props.advertiser].buyer} can help you pay off your debt for less than you owe and get you on the road to becoming debt-free.</p>
                    <p><strong>For priority support, call <a href="tel:${advertiserMapping[props.advertiser].did.replace(/-/g, '')}" class="text-link">${advertiserMapping[props.advertiser].did}</a> and a ${advertiserMapping[props.advertiser].buyer} support agent will be able to help you immediately.</strong></p>
                    <p>The consultation is 100% free, confidential, and requires absolutely no commitment.</p>
                    <a href="tel:${advertiserMapping[props.advertiser].did.replace(/-/g, '')}" class="cta-phone">Call Now For Priority Support</a>
                `
            } else {
                return `
                    <p>You don't need to be overwhelmed by your debt. ${advertiserMapping[props.advertiser].buyer} can help you pay off your debt for less than you owe and get you on the road to becoming debt-free.</p>
                    <p>A debt relief specialist from <strong>${advertiserMapping[props.advertiser].buyer}</strong> will contact your shortly. Be sure to answer your phone.</p>
                    <p>The consultation is 100% free, confidential, and requires absolutely no commitment.</p>
                `
            }
        } else {
            return `
                <p>You don't need to be overwhelmed by your debt. We can help you pay off your debt for less than you owe and get you on the road to becoming debt-free.</p>
                <p>A debt relief specialist will contact your shortly. Be sure to answer your phone.</p>
                <p>The consultation is 100% free, confidential, and requires absolutely no commitment.</p>
            `
        }
	})

    const buyerName = computed(() => {
        return advertiserMapping[props.advertiser] ? advertiserMapping[props.advertiser].buyer : ''
    })

    const hasReviews = computed(() => {
        return advertiserMapping[props.advertiser]?.reviews?.length > 0
    })

    const reviewHeadline = computed(() => {
        return advertiserMapping[props.advertiser]?.reviewHeadline || ''
    })

    const reviews = computed(() => {
        return advertiserMapping[props.advertiser]?.reviews || []
    })

    const reviewHeadlineImg = computed(() => {
        return advertiserMapping[props.advertiser]?.reviewHeadlineImg || ''
    })
</script>

<template>
	<section class="container">
		<div class="row justify-content-center text-center">
			<div class="col-12 col-xl-8 mt-4">
                <h1>You qualify for a free debt relief consultation!</h1>
                <div v-html="textString"></div>

                <hr class="customline">

                <template v-if="hasReviews">
                    <h3 class="review-headline">{{ reviewHeadline }}</h3>
                    <div v-if="reviewHeadlineImg" class="headline-img-container">
                        <img :src="`https://firstadvantage.io/app/static/${reviewHeadlineImg}.jpg`" :alt="reviewHeadline" class="headline-img">
                    </div>
                    
                    <div class="reviews-container">
                        <div v-for="(review, index) in reviews" :key="index" class="review-box">
                            <div class="row g-0">
                                <div class="col-3 review-img-col pe-2">
                                    <img :src="`https://firstadvantage.io/app/static/${review.img}.jpg`" :alt="review.title" class="review-img">
                                </div>
                                <div class="col-9">
                                    <div class="review-content">
                                        <p class="review-name">{{ review.title }}</p>
                                        <p class="review-text">{{ review.review }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <a :href="`tel:${advertiserMapping[props.advertiser].did.replace(/-/g, '')}`" class="cta-phone">Call Now For Priority Support</a>
                    <hr class="customline">
                </template>

                <h3>What Happens Next?</h3>

                <p class="listPoint">You'll soon receive a call from a certified {{ buyerName }} debt relief specialist.</p>
                <p class="listPoint">Your debt specialist will be able to review your information, make any necessary changes, and customize your plan to helping you become debt free.</p>
                <p class="listPoint">The consultation is free, confidential, and requires absolutely no commitment.</p>
			</div>
		</div>
	</section>
</template>

<style scoped>
    .container {
        min-height: 600px;
        min-height: 65vh;
    }
    .review-box {
        text-align: left;
        margin-bottom: 15px;
    }

    .review-content {
        background-color: #fafafa;
        padding: 20px;
        border-radius: 8px;
    }

    .review-name {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .review-img-col {
        text-align: center;
    }

    .review-img {
        max-width: 100px;
        border-radius: 50%;
        aspect-ratio: 1;
        object-fit: cover;
        margin-top: 10px;
    }

    .review-date {
        text-align: right;
        float: right;
    }

    .review-text {
        font-weight: 300;
        font-size: .93em;
    }
    a.phone-cta,
    p.phone-cta {
        color: #ff8800;
        font-size: 2em;
        font-weight: 500;
        display: block;
        cursor: pointer;
    }
    .bbb-review-img {
        max-width: 150px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .customline {
        margin-top: 40px;
    }

    h1 {
        margin-top: 10px;
    }

    h3 {
        margin-top: 70px;
        margin-bottom: 50px;
        font-weight: 700;
    }

    .listPoint {
        text-align: left;
        padding-left: 60px;
        margin-bottom: 30px;
        position: relative;
    }

    .listPoint::before {
        display: inline-block;
        position: absolute;
        left: 0;
        top: -6px;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font: var(--fa-font-regular);
        content: '\f058';
        font-size: 40px;
        margin-right: 20px;
    }
	
    .reviews-container {
        margin: 40px auto;
        max-width: 600px;
    }

    .review-headline {
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .headline-img-container {
        text-align: center;
        margin: 0px 0 30px;
    }

    .headline-img {
        max-width: 300px;
        height: auto;
    }

    @media (max-width: 767px) {
        .review-img {
            margin-top: 15px;
            max-width: 80px;
        }

        .review-content {
            padding: 15px;
            margin-top: 5px;
        }

        .reviews-container {
            max-width: 100%;
        }
    }
</style>
<style>
    a.cta-phone {
        background-color: #0D496F;
        border-color: #0D496F;
        padding: 20px 0;
        font-size: 1.4em;
        font-weight: 700;
        border-radius: 39px;
        transition: all .3s ease;
        box-shadow: 0 10px 15px #00ACEF4d;
        display: block;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        color: #fff;
    }

    a.text-link {
        color: #0D496F;
        text-decoration: underline;
    }
</style>