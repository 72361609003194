<script setup>
	import { defineProps, defineEmits, ref, computed } from 'vue'
    import Chat from '../Chat.vue'

	const props = defineProps({
		question: {
			type: Object,
			required: true
		}
	})
    const emit = defineEmits(['proceed'])

    const displayValidation = ref(false)
    function onSubmit() {
        let description = props.question.value;
        // perform validations
        if (description.length < 5) {
            displayValidation.value = true
        } else {
            console.log('proceed')
            emit('proceed')
        }
    }
</script>

<template>
    <section class="btn-options" :class="{'was-validated' : displayValidation}">
        <h3 class="questionHeading">{{ question.headline }}</h3>

        <div class="form-floating mb-3">
            <textarea 
                class="form-control"
                id="description"
                placeholder="Enter your description"
                minlength="5"
                required
                v-model="question.value"
                style="height: 100px"
                @keydown.enter="onSubmit"></textarea>
            <label for="description">Enter your description</label>
            <div class="invalid-feedback">Please provide a brief description (min 5 characters).</div>
        </div>

        <button class="btn btn-primary" @click="onSubmit">Submit <i class="fa-solid fa-arrow-right" style="color: #ffffff;"></i></button>

        <div v-if="question.chat">
            <Chat :message="question.chat" />
        </div>
    </section>
</template>

<style scoped>
    .btn-options {
        min-height: 78vh;
        position: relative;
    }
    .questionHeading {
        font-size: 1.2em;
        font-weight: 600;
        color: #2a2a2b;
        text-align: center;
        line-height: 1.2;
        margin-bottom: 20px;
    }
    .btn-primary {
        background-color: #0D496F;
        border-color: #0D496F;
        padding: 20px 42px;
        font-size: 1.4em;
        font-weight: 700;
        border-radius: 39px;
        /* position: absolute;
        right: 0;
        bottom: 22px; */
        transition: all .3s ease;
        box-shadow: 0 10px 15px #00ACEF4d;
        display: block;
        width: 100%;
        margin-top: 40px;
    }

    .btn-primary:disabled {
        background-color: #ddd;
        border-color: #efefef;
        box-shadow: none;
    }

    .form-control {
        border-color: #ccc;
        border-radius: 3px;
        box-shadow: 0 3px 0 #dbdBE866;
        position: relative;
        padding-left: 18px;
    }

    .form-floating>label {
        padding-left: 20px;
        color: #ccc;
    }

    .form-floating>.form-control:focus~label,
    .form-floating>.form-control:not(:placeholder-shown)~label {
        transform: scale(.75) translateY(-.5rem) translateX(.25rem);
    }

    .form-control:active,
    .form-control:focus,
    .form-control:hover {
        border-width: 2px;
        border-color: #f9892a;
        border-style: solid;
    }

    @media (min-width: 551px) {
        /* .btn-primary {
            position: relative;
            right: auto;
            left: auto;
            bottom: auto;
            display: block;
            width: 100%;
            margin-top: 40px;
        } */
    }
</style>
