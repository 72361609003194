<script setup>
	import { defineProps, defineEmits, ref, onMounted } from 'vue'
    import Chat from '../Chat.vue'
    import { vMaska } from "maska"
    import { stateOptions } from '../../constants/selectOptions';

	const props = defineProps({
		question: {
			type: Object,
			required: true
		}
	})
    const emit = defineEmits(['proceed'])

    const displayValidation = ref(false)
    const zipInvalid = ref(false)

    function checkZip() {
        if (props.question.overrideValidation) {
            if (props.question.zip.length === 5) {
                props.question.overrideValidation = false
                zipInvalid.value = false
            }
        }
    }

    function fillInAddress() {
        // Get the place details from the autocomplete object.
        var place = autocomplete.getPlace(),
        street_number = '',
        route = '',
        locality = '',
        administrative_area_level_1 = '',
        postal_code = '';

        if (place) {
            // Get each component of the address from the place details,
            // and then fill-in the corresponding field on the form.
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (addressType === 'street_number') {
                    street_number = place.address_components[i].short_name;
                } else if (addressType === 'route') {
                    route = place.address_components[i].long_name;
                } else if (addressType === 'locality') {
                    locality = place.address_components[i].long_name;
                } else if (addressType === 'administrative_area_level_1') {
                    administrative_area_level_1 = place.address_components[i].short_name;
                } else if (addressType === 'postal_code') {
                    postal_code = place.address_components[i].short_name;
                }
            }

            props.question.address = street_number + ' ' + route;
            props.question.zip = postal_code;
            props.question.state = administrative_area_level_1;
            props.question.city = locality;
        }
    }

    function onSubmit() {
        const address = props.question.address;
        const zip = props.question.zip;
        const city = props.question.city;
        const state = props.question.state;
        // perform validations
        if (!address ||
            !zip) {
            displayValidation.value = true;
        } else {
            if (zip.length < 5) {
                props.question.overrideValidation = true
                zipInvalid.value = true
            } else {
                emit('proceed')
            }
        }
    }

    let autocomplete = {}
    function initAutocomplete() {
        autocomplete = new google.maps.places.Autocomplete(document.getElementById('address'), {types: ['geocode']});
        autocomplete.setFields(['address_component']);
        autocomplete.addListener('place_changed', fillInAddress);
        autocomplete.setComponentRestrictions({'country': ['us', 'pr', 'vi', 'gu', 'mp']});
    }
    onMounted(() => {
        // Create the autocomplete object
        if (typeof google === 'object') {
            initAutocomplete()
        } else {
            let waitForGoogle = window.setInterval(function() {
                if (typeof google === 'object') {
                    initAutocomplete()
                    window.clearInterval(waitForGoogle)
                }
            }, 500)
        }
    })
</script>

<template>
    <section class="btn-options" :class="{'was-validated' : displayValidation}">
        <h3 class="questionHeading">What’s your mailing address?</h3>
        <h5 class="questionSubHeading">This will help us find specific solutions for you.</h5>

        <div class="form-floating mb-3 input-address">
            <input 
                type="text"
                class="form-control"
                id="address"
                placeholder="Address"
                required
                v-model="question.address"
                @keydown.enter="onSubmit">
            <label for="address">Address</label>
            <div class="invalid-feedback">Please provide your address</div>
        </div>

        <div class="form-floating mb-3">
            <input
                v-maska
                data-maska="#####"
                minlength="5"
                class="form-control"
                :class="{'is-invalid': zipInvalid}"
                id="zip"
                placeholder="Zip code"
                required
                v-model="question.zip"
                @keyup="checkZip"
                @keydown.enter="onSubmit">
            <label for="Zip">Zip</label>
            <div class="invalid-feedback">Please provide a valid US zip code</div>
        </div>

        <button class="btn btn-primary" :disabled="!question.validated" @click="onSubmit">Next <i class="fa-solid fa-arrow-right" style="color: #ffffff;"></i></button>

        <div v-if="question.chat">
            <Chat :message="question.chat" />
        </div>
    </section>
</template>

<style scoped>
    .btn-options {
        min-height: 65vh;
        position: relative;
    }
    .questionHeading {
        font-size: 1.6em;
        font-weight: 700;
        color: #2a2a2b;
        text-align: center;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    .questionSubHeading {
        font-size: 1em;
        text-align: center;
        margin-bottom: 30px;
    }

    .btn-primary {
        background-color: #0D496F;
        border-color: #0D496F;
        padding: 20px 42px;
        font-size: 1.4em;
        font-weight: 700;
        border-radius: 39px;
        /* position: absolute;
        right: 0;
        bottom: 22px; */
        transition: all .3s ease;
        box-shadow: 0 10px 15px #00ACEF4d;
        display: block;
        width: 100%;
        margin-top: 40px;
    }

    .btn-primary:disabled {
        /* background-color: #ddd;
        border-color: #efefef; */
        box-shadow: none;
    }

    .form-control {
        border-color: #ccc;
        border-radius: 3px;
        box-shadow: 0 3px 0 #dbdBE866;
        position: relative;
        padding-left: 18px;
        height: 70px;
    }

    .form-floating>label {
        padding-left: 24px;
        color: #ccc;
        padding: 1.2rem;
    }

    .form-floating>.form-control:focus~label,
    .form-floating>.form-control:not(:placeholder-shown)~label {
        transform: scale(.75) translateY(-.5rem) translateX(.25rem);
    }

    .form-control:active,
    .form-control:focus,
    .form-control:hover {
        border-width: 2px;
        border-color: #f9892a;
        border-style: solid;
    }

    .disclaimer {
        font-size: .6em;
        line-height: 1.5;
        text-align: center;
        margin-top: 30px;
    }

    .form-floating {
        position: relative;
    }

    .input-address:after {
        content: "\f3c5";
        position: absolute;
        display: inline-block;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font: var(--fa-font-regular);
        color: #3C91E6;
        right: 12px;
        top: 18px;
        font-size: 1.9em;
    }

    @media (min-width: 551px) {
        
    }
</style>