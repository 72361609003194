<script setup>
	import { defineProps, defineEmits } from 'vue';
    import Buttons from './formTypes/Buttons.vue';
    import ButtonIcons from './formTypes/ButtonIcons.vue';
    import Range from './formTypes/Range.vue';
    import Select from './formTypes/Select.vue';
    import Name from './formTypes/Name.vue';
    import Email from './formTypes/Email.vue';
    import Contact from './formTypes/Contact.vue';
    import Address from './formTypes/Address.vue';
    import Dob from './formTypes/Dob.vue';
    import OpenText from './formTypes/OpenText.vue';
    import Content from './Content.vue';

	const props = defineProps({
		question: {
			type: Object,
			required: true
		},
        removeQuestion: {
            type: Boolean
        },
        direction: {
            type: String
        },
        backButtonVisible: {
            type: Boolean
        },
        hasLocationData: {
            type: Boolean
        },
        firstName: {
            type: String
        },
        mainHeadline: {
            type: String
        },
        mainSubHeadline: {
            type: String
        }
	})
    const emit = defineEmits(['proceed','goBack','hideBackButton','showBackButton'])

    function submitForm() {
        // Do nothing on default form submit
    }
</script>

<template>
    <main class="container main-content" id="main">
        <div class="row justify-content-center text-center headings">
            <div class="col-12 col-xl-10">
                <div v-if="question.showHeadlines && mainHeadline !== null">
                    <h1 class="heading">{{mainHeadline}}</h1>
                    <h2 class="subheading">{{mainSubHeadline}}</h2>
                </div>
            </div>
        </div>
        <section class="row question">
            <form class="col" @submit.prevent="submitForm">
                <Transition :name="direction === 'forwards' ? 'fade' : 'reverse'" mode="out-in">
                    <div v-if="!removeQuestion">
                        <Buttons
                            v-if="question.type === 'buttons'"
                            :question="question"
                            @proceed="$emit('proceed',$event)" />
                        <ButtonIcons
                            v-if="question.type === 'buttons-icons'"
                            :question="question"
                            @proceed="$emit('proceed',$event)" />
                        <Range
                            v-if="question.type === 'range'"
                            :question="question"
                            @proceed="$emit('proceed',$event)" />
                        <Select
                            v-else-if="question.type === 'select'"
                            :question="question"
                            @proceed="$emit('proceed')" />
                        <Name
                            v-else-if="question.type === 'name'"
                            :question="question"
                            @proceed="$emit('proceed')" />
                        <Email
                            v-else-if="question.type === 'email'"
                            :question="question"
                            :firstName="firstName"
                            @proceed="$emit('proceed')"
                            @hideBackButton="$emit('hideBackButton')"
                            @showBackButton="$emit('showBackButton')" />
                        <Contact
                            v-else-if="question.type === 'contact'"
                            :question="question"
                            @proceed="$emit('proceed')"
                            @hideBackButton="$emit('hideBackButton')"
                            @showBackButton="$emit('showBackButton')" />
                        <Dob
                            v-else-if="question.type === 'dob'"
                            :question="question"
                            @proceed="$emit('proceed')"
                            @hideBackButton="$emit('hideBackButton')"
                            @showBackButton="$emit('showBackButton')" />
                        <Address
                            v-else-if="question.type === 'address'"
                            :question="question"
                            @proceed="$emit('proceed')" />
                        <OpenText
                            v-else-if="question.type === 'openText'"
                            :question="question"
                            @proceed="$emit('proceed')" />
                    </div>
                </Transition>
            </form>
        </section>
        <a role="button" tabindex="0" class="back back-mb" v-if="question.showProgress && backButtonVisible" @click="$emit('goBack')"><i class="fa-solid fa-arrow-left"></i></a>
        <input id="leadid_token" name="universal_leadid" type="hidden" value=""/>
    </main>
    
	<Content></Content>
</template>

<style scoped>
    .main-content {
        min-height: 550px;
        min-height: 90vh;
        position: relative;
    }
    .headings {
        margin-top: 2em;
    }
    .heading {
        font-size: 1.8em;
        font-weight: 600;
        margin-bottom: 15px;
        line-height: 1.2;
    }
    .subheading {
        font-size: 1.2em;
        margin-bottom: 0;
        font-style: italic;
    }

    .question {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2.5em;
    }

    .back {
		position: absolute;
        left: 20px;
        bottom: 39px;
        color: #ababab;
        font-weight: 800;
        cursor: pointer;
        display: none;
        font-size: 2em;
	}

	.back:hover,
    .back:active {
		color: #19329a;
		background-color: transparent;
	}

    @media (max-width: 767px) {
        .headings {
            margin-top: 1.5em;
        }
        .heading {
            font-size: 1.4em;
        }
        .subheading {
            font-size: 1em;
        }

        .question {
            max-width: 100%;
            margin-top: 1.6em;
        }
    }

    @media (max-width: 550px) {
        .back {
            display: block;
        }
    }
</style>
