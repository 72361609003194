<script setup>
	import { computed, defineProps } from 'vue'
	import Progress from './Progress.vue'
	import { advertiserMapping } from '../constants/selectOptions.js'

	const props = defineProps({
		progressPercent: {
			type: Number,
			default: '10'
		},
		showProgress: {
			type: Boolean
		},
		backButtonVisible: {
			type: Boolean
		},
		advertiser: {
			type: String
		}
	})
	const emit = defineEmits(['goBack'])

	const logoUrl = computed(() => {
		return advertiserMapping[props.advertiser] ? advertiserMapping[props.advertiser].url : 'logo'
	})

	const logoUrlString = computed(() => {
		return 'https://firstadvantage.io/app/static/' + logoUrl.value + '.png'
	})
</script>

<template>
	<header class="container-fluid header-container" id="header">
		<div class="row justify-content-between">
			<div class="col align-self-center">
				<img 
					:src="logoUrlString"
					alt="First Advantage Financial"
					class="img-fluid logo"
					width="120"
					height="66">
			</div>
			<div class="col"></div>
		</div>
		<a role="button" tabindex="0" class="back back-desktop" v-if="showProgress && backButtonVisible" @click="$emit('goBack')"><i class="fa-solid fa-arrow-left"></i> Back</a>
	</header>
	<Progress v-if="showProgress" :progressPercent="progressPercent" />
</template>

<style scoped>
	.header-container {
		border-bottom: 1px solid #ccc;
		position: relative;
	}

	.logo {
		max-width: 120px;
		max-height: 77px;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.back {
		position: absolute;
		left: 15px;
		bottom: -33px;
		color: #ababab;
		font-weight: 600;
		cursor: pointer;
		display: none;
	}

	.back:hover {
		color: #19329a;
		background-color: transparent;
	}

	@media (min-width: 550px) {
		.logo {
			max-width: 140px;
		}

		.back {
			display: block;
		}
	}
</style>
