const ageOptions = [
	{
		text: "Under 30",
		value: "29"
	}
]
const stateOptions = [
	{
		value: "AL",
		text: "Alabama"
	},
	{
		value: "AK",
		text: "Alaska"
	},
	{
		value: "AS",
		text: "American Samoa"
	},
	{
		value: "AA",
		text: "Armed Forces Americas"
	},
	{
		value: "AP",
		text: "Armed Forces Pacific"
	},
	{
		value: "AE",
		text: "Armed Forces Others"
	},	
	{
		value: "AZ",
		text: "Arizona"
	},
	{
		value: "AR",
		text: "Arkansas"
	},
	{
		value: "CA",
		text: "California"
	},
	{
		value: "CO",
		text: "Colorado"
	},
	{
		value: "CT",
		text: "Connecticut"
	},
	{
		value: "DE",
		text: "Delaware"
	},
	{
		value: "DC",
		text: "District Of Columbia"
	},
	{
		value: "FL",
		text: "Florida"
	},
	{
		value: "GA",
		text: "Georgia"
	},
	{
		value: "GU",
		text: "Guam"
	},
	{
		value: "HI",
		text: "Hawaii"
	},
	{
		value: "ID",
		text: "Idaho"
	},
	{
		value: "IL",
		text: "Illinois"
	},
	{
		value: "IN",
		text: "Indiana"
	},
	{
		value: "IA",
		text: "Iowa"
	},
	{
		value: "KS",
		text: "Kansas"
	},
	{
		value: "KY",
		text: "Kentucky"
	},
	{
		value: "LA",
		text: "Louisiana"
	},
	{
		value: "ME",
		text: "Maine"
	},
	{
		value: "MD",
		text: "Maryland"
	},
	{
		value: "MA",
		text: "Massachusetts"
	},
	{
		value: "MI",
		text: "Michigan"
	},
	{
		value: "MN",
		text: "Minnesota"
	},
	{
		value: "MS",
		text: "Mississippi"
	},
	{
		value: "MO",
		text: "Missouri"
	},
	{
		value: "MT",
		text: "Montana"
	},
	{
		value: "NE",
		text: "Nebraska"
	},
	{
		value: "NV",
		text: "Nevada"
	},
	{
		value: "NH",
		text: "New Hampshire"
	},
	{
		value: "NJ",
		text: "New Jersey"
	},
	{
		value: "NM",
		text: "New Mexico"
	},
	{
		value: "NY",
		text: "New York"
	},
	{
		value: "NC",
		text: "North Carolina"
	},
	{
		value: "ND",
		text: "North Dakota"
	},
	{
		value: "MP",
		text: "Northern Mariana Islands"
	},
	{
		value: "OH",
		text: "Ohio"
	},
	{
		value: "OK",
		text: "Oklahoma"
	},
	{
		value: "OR",
		text: "Oregon"
	},
	{
		value: "PA",
		text: "Pennsylvania"
	},
	{
		value: "PR",
		text: "Puerto Rico"
	},
	{
		value: "RI",
		text: "Rhode Island"
	},
	{
		value: "SC",
		text: "South Carolina"
	},
	{
		value: "SD",
		text: "South Dakota"
	},
	{
		value: "TN",
		text: "Tennessee"
	},
	{
		value: "TX",
		text: "Texas"
	},
	{
		value: "UM",
		text: "United States Minor Outlying Islands"
	},
	{
		value: "UT",
		text: "Utah"
	},
	{
		value: "VT",
		text: "Vermont"
	},
	{
		value: "VI",
		text: "Virgin Islands"
	},
	{
		value: "VA",
		text: "Virginia"
	},
	{
		value: "WA",
		text: "Washington"
	},
	{
		value: "WV",
		text: "West Virginia"
	},
	{
		value: "WI",
		text: "Wisconsin"
	},
	{
		value: "WY",
		text: "Wyoming"
	},
]

for (var i = 30; i < 66; i++) {
	ageOptions.push({
		text: String(i),
		value: String(i)
	});
}
ageOptions.push({
	text: "Over 65",
	value: "66"
});

function setCookie(name,value,days) {
	var expires = "";
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days*24*60*60*1000));
		expires = "; expires=" + date.toUTCString();
	}
	document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function getCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for(var i=0;i < ca.length;i++) {
		var c = ca[i];
		while (c.charAt(0)==' ') c = c.substring(1,c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
	}
	return null;
}

const advertiserMapping = {
	"4": {
		"buyer": "ClearOne Advantage",
		"url": "clearone",
		"did": "1-888-246-1312"
	},
	"3": {
		"buyer": "Freedom Debt",
		"url": "freedom",
		"did": "1-888-247-6313"
	},
	"2": {
		"buyer": "JG Wentworth",
		"url": "jgwentworth",
		"did": "1-888-421-6993"
	},
	"9": {
		"buyer": "Century Support Services",
		"url": "century",
		"did": null
	},
	"16": {
		"buyer": "NOLO Attorney Network",
		"url": "logo",
		"did": null
	},
	"20": {
		"buyer": "Credit Associates",
		"url": "creditassociates",
		"did": "1-888-458-8159"
	},
	"21": {
		"buyer": "National Debt Relief",
		"url": "ndr",
		"did": "1-866-633-2108",
		"reviewHeadline": "National Debt Relief has over 35,000 5-star reviews on TrustPilot.",
		"reviewHeadlineImg": "trustpilot",
		"reviews": [
			{
				"title": "Jay H. Saved 37% On His Debt",
				"review": "Now I wake up knowing that I am paying off my debt, it's like a weight lifted off my chest and I can breathe a bit more.",
				"img": "ndr-jay"
			},
			{
				"title": "Madelyn T. Saved 38% On Her Debt",
				"review": "There's a sense of relief that I don't have to worry about drowning in debt anymore, when I know National Debt Relief is there to help me.",
				"img": "ndr-madelyn"
			},
			{
				"title": "Katelyn O. Saved 21% On Her Debt",
				"review": "I'm finally able to save and I have so many plans for the future, it's this overwhelming feeling of relief knowing that I can check paying off my debt from my list of things to take care of.",
				"img": "ndr-katelyn"
			}
		]
	},
	"19": {
		"buyer": "Accredited Debt Relief",
		"url": "adr",
		"did": "1-888-421-6991",
		// "reviewHeadline": "Accredited Debt Relief has over 7,000 5-star reviews on TrustPilot.",
		// "reviewHeadlineImg": "trustpilot",
		// "reviews": [
		// 	{
		// 		"title": "Client A. Saved 30% On Their Debt",
		// 		"review": "I feel a great sense of relief knowing that I'm making progress in paying off my debt.",
		// 		"img": "stock"
		// 	},
		// 	{
		// 		"title": "Client B. Saved 35% On Their Debt",
		// 		"review": "It's such a relief to know that I have support in managing my debt and can focus on my future.",
		// 		"img": "stock"
		// 	},
		// 	{
		// 		"title": "Client C. Saved 25% On Their Debt",
		// 		"review": "I can finally see a path forward and feel less stressed about my financial situation.",
		// 		"img": "stock"
		// 	}
		// ]
	},
	"22": {
		"buyer": "Skyline Financials",
		"url": "skyline",
		"did": null
	},
	"23": {
		"buyer": "Renew Debt Relief",
		"url": "renew",
		"did": "1-855-719-9019"
	},
	"101": {
		"buyer": "Debt Blue",
		"url": "debtblue",
		"did": "1-888-888-8888"
	}
}

export { ageOptions, stateOptions, setCookie, getCookie, advertiserMapping }