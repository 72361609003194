<script setup>
    import { ref, computed } from 'vue'

    let domainName = ref(window.location.host)
    let prettyName = domainName.value.replace('first','First').replace('advantage','Advantage').replace('financial','Financial')

    let complianceLink = computed(() => {
        return domainName.value.includes('start.') ? 'firstadvantage.io' : domainName.value
    })
</script>

<template>
	<footer class="container-fluid footer-container">
		<div class="row text-center mt-3">
            <div class="col-12 col-lg-10 offset-lg-1">
                <img src="../assets/logo.png" alt="First Advantage Financial" class="footer-logo mt-5">
                <p class="footer-legal mt-4">This website is owned and operated by First Advantage Financial (<span v-text="domainName"></span>). By registering for access to any portion of this website or by using any portion of this website, you agree to be bound by these terms and conditions. Please review our other policies, such as our privacy policy. These policies also govern your use of this website. If you do not agree to be bound by these terms and conditions, do not proceed any further and do not use this website.</p>
                <p class="footer-legal mt-4">By completing the form above, I am providing "written instructions" to Array US, Inc. authorizing it to obtain my credit profile from any consumer reporting agency which can be used to evaluate my financial history and provide me with targeted offers based on my credit profile. We may receive a referral fee or other affiliate fee for connecting you with these third-party companies or upon you contracting with a third-party company. The order in which options are presented may be impacted by that compensation.</p>
                <p class="footer-legal">&copy; 2025 <span v-text="prettyName"></span></p>
                <div class="footer-links">
                    <ul class="footer-list">
                        <li class="footer-list-item"><a :href="'https://' + complianceLink + '/privacy-policy'" target="_blank" class="footer-link">Privacy Policy</a></li>
                        <li class="footer-list-item"><a :href="'https://' + complianceLink + '/terms-of-use'" target="_blank" class="footer-link">Terms & Conditions</a></li>
                        <li class="footer-list-item"><a href="https://optconsumerprivacy.com/?site=fa" class="footer-link">Do Not Sell My Personal Information</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<style scoped>
	.footer-container {
		border-top: 1px solid #ccc;
		position: relative;
	}

    .footer-legal {
        font-size: .7em;
    }

    .footer-list {
        list-style: none;
        text-align: center;
        padding: 0;
    }

    .footer-list-item {
        display: inline-block;
        margin-left: 5px;
        margin-right: 5px;
    }

    .footer-link {
        text-decoration: none;
        color: #aaa;
        text-align: center;
        font-size: .7em;
    }
    .footer-links:hover {
        color: #000;
    }

    .footer-logo {
        max-width: 150px;
    }
</style>
