<script setup>
	import { defineProps, ref } from 'vue'

	const props = defineProps({
		messages: {
			type: Array
		}
	})

    let messageIndex = 0
    const message = ref(props.messages[messageIndex])
    var updateSpinner = window.setInterval(function() {
        messageIndex++
        message.value = props.messages[messageIndex]
        if (messageIndex === props.messages.length - 1) {
            clearInterval(updateSpinner)
        }
    }, 4000)
</script>

<template>
   <div class="row spinner-row justify-content-center">
        <div class="col text-center">
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            <p class="spinnerText">{{ message }}</p>
        </div>
   </div>
</template>

<style scoped>
    .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-ellipsis div {
        position: absolute;
        top: 33px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: #19329a;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .lds-ellipsis div:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(24px, 0);
        }
    }

</style>
