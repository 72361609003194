<script setup>
	import { defineProps, defineEmits, ref, watch, computed } from 'vue'
    import Chat from '../Chat.vue'
    import Spinner from '../Spinner.vue'
    import NetworkMembers from '../NetworkMembers.vue'
    import axios from "axios"
    import { vMaska } from "maska"

	const props = defineProps({
		question: {
			type: Object,
			required: true
		}
	})
    const emit = defineEmits(['proceed','hideBackButton','showBackButton'])

    const headline = computed(() => {
        return "And finally, what's your phone number?"
    })
    const subheadline = computed(() => {
        return "No spam calls. Ever."
    })

    const validEmailTypes = [
        'Verified',
        'Role',
        'Unknown',
        'Catch All',
        'Complainer',
        'Disposable Email',
        'Suppressed'
    ]
    const AC_Invalid = [
        'Unknown',
        'Catch All',
        'Complainer',
        'Disposable Email',
        'Suppressed',
        'Bot',
        'Undeliverable',
        'Malformed',
        'SpamTrap'
    ]
    const displayValidation = ref(false)
    const phoneInvalid = ref(false)
    const validating = ref(false)
    const showNetworkMembers = ref(false)

    watch(validating, async (value) => {
        if (value) {
            emit('hideBackButton')
        } else {
            emit('showBackButton')
        }
    })

    function checkPhone() {
        if (props.question.overrideValidation) {
            if (props.question.maskaObject.completed) {
                props.question.overrideValidation = false
                phoneInvalid.value = false
            }
        }
    }

    function sendValidations() {
        validating.value = true
        document.getElementById('header').scrollIntoView();

        axios.get('https://api.apilayer.com/number_verification/validate?number=1' + props.question.maskaObject.unmasked, {headers: {apikey: '4ykhNgxn0OzSYQYq1MW7hjJYXozN4rZt'}, timeout: 3000}).then(function(response) {
        // axios.get('https://apilayer.net/api/validate?access_key=0fa8dd9f0511b54203349edd033600cc&number=1' + props.question.maskaObject.unmasked, {headers: {}, timeout: 3000}).then(function(response) {
            console.log(response)
            if (!response.data) {
                backupValidations()
            }
            const phone_response = response.data
            console.log(phone_response)
            if ( (!phone_response.valid) || (phone_response.country_code !== 'US') ) {
                props.question.overrideValidation = true
                phoneInvalid.value = true
                displayValidation.value = true
                validating.value = false
                return false;
            }
            displayValidation.value = false
            phoneInvalid.value = false
            validating.value = false

            emit('proceed')
        }).catch(function(err) {
            console.log(err)
            backupValidations()
        })
    }

    function backupValidations() {
        axios.get('https://isy6rbqwbqd6irk5ruqsiouuze0vjrqs.lambda-url.us-east-2.on.aws/?phone=' + props.question.maskaObject.unmasked + '&type=fake', {headers: {}, timeout: 10000}).then(function(response) {
            console.log(response);
            if (response.data === 'Fake') {
                props.question.overrideValidation = true
                phoneInvalid.value = true
                displayValidation.value = true
                validating.value = false
                return false;
            }
            displayValidation.value = false
            phoneInvalid.value = false
            validating.value = false

            emit('proceed')
        }).catch(function(err) {
            console.log(err);
            displayValidation.value = false
            phoneInvalid.value = false
            validating.value = false

            emit('proceed')
        })
    }

    function onSubmit() {
        let phone = props.question.maskaObject.unmasked;
        // perform validations
        if (!phone ||
            !props.question.maskaObject.completed) {
            displayValidation.value = true;
        } else {
            sendValidations()
        }
    }

    function hideNetwork() {
        showNetworkMembers.value = false
    }
    
    function showNetwork() {
        showNetworkMembers.value = true
    }

    let domainName = ref(window.location.host)

    let complianceLink = computed(() => {
        return domainName.value.includes('start.') ? 'firstadvantage.io' : domainName.value
    })
</script>

<template>
    <Spinner v-if="validating" :messages="['Verifying your information...','Reviewing your eligibility...','Checking to see if you qualify...']" />
    <section v-else class="btn-options" :class="{'was-validated' : displayValidation}">
        <h3 class="questionHeading">{{ headline }}</h3>
        <h5 class="questionSubHeading" v-if="subheadline">{{ subheadline }}</h5>

        <div class="form-floating mb-3">
            <input
                v-maska="question.maskaObject"
                data-maska="(###) ###-####"
                class="form-control"
                :class="{'is-invalid': phoneInvalid}"
                id="phone"
                placeholder="Phone"
                required
                v-model="question.value"
                @keyup="checkPhone"
                @keydown.enter="onSubmit">
            <label for="Phone">Phone</label>
            <div class="invalid-feedback">Please provide a valid US phone number</div>
        </div>

        <button class="btn btn-primary" :disabled="!question.validated" @click="onSubmit">See Results <i class="fa-solid fa-arrow-right" style="color: #ffffff;"></i></button>

        <p class="disclaimer">By Clicking SEE RESULTS and submitting my form, I am providing my electronic signature in which I agree to the following: (a) the posted <a :href="'https://' + complianceLink + '/privacy-policy'" target="_blank">Privacy Policy</a> and <a :href="'https://' + complianceLink + '/terms-of-use'" target="_blank">Terms of Use</a>, including the arbitration provision and waiver of participation in a class action litigation; and (b) I hereby provide my express written consent to share my information, including the mobile number with First Advantage Financial, JG Wentworth, Clearone Advantage, Freedom Debt Relief, TurboDebt, Beyond Finance LLC dba Accredited Debt Relief, National Debt Relief, Renew Debt Relief, ARC Advantage, Upside Legal LLC, Resource Match and Rooted Realm calling or texting on their behalf who may contact me about their products and services which may be different than those on this website; and (c) I consent for each to contact me through automatic telephone dialing system, text, artificial or pre-recorded messages via telephone, mobile device including SMS and MMS and/or email, even if my telephone number is listed on any state, federal or corporate Do Not Call registry. I understand that my consent is not required as a condition to purchase a good or service. I may opt out from SMS or Text messages at any time by replaying STOP. Data and SMS rates may apply. I may revoke this consent at any time.</p>
        <div v-if="question.chat">
            <Chat :message="question.chat" />
        </div>
    </section>

    <NetworkMembers :showNetworkMembers="showNetworkMembers" @hideNetwork="hideNetwork" />
</template>

<style scoped>
    .btn-options {
        min-height: 65vh;
        position: relative;
    }
    .questionHeading {
        font-size: 1.6em;
        font-weight: 700;
        color: #2a2a2b;
        text-align: center;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    .questionPreHeading {
        color: #19329a;
        font-style: italic;
        font-size: .9em;
        font-weight: 600;
        text-align: center;
        margin-bottom: 5px;
    }

    .questionSubHeading {
        font-size: 1em;
        text-align: center;
        margin-bottom: 35px;
    }

    .btn-primary {
        background-color: #0D496F;
        border-color: #0D496F;
        padding: 20px 42px;
        font-size: 1.4em;
        font-weight: 700;
        border-radius: 39px;
        /* position: absolute;
        right: 0;
        bottom: 22px; */
        transition: all .3s ease;
        box-shadow: 0 10px 15px #00ACEF4d;
        display: block;
        width: 100%;
        margin-top: 40px;
    }

    .btn-primary:disabled {
        /* background-color: #ddd;
        border-color: #efefef; */
        box-shadow: none;
    }

    .form-control {
        border-color: #ccc;
        border-radius: 3px;
        box-shadow: 0 3px 0 #dbdBE866;
        position: relative;
        padding-left: 18px;
        height: 70px;
    }

    .form-floating>label {
        padding-left: 20px;
        color: #ccc;
        padding: 1.2rem;
    }

    .form-floating>.form-control:focus~label,
    .form-floating>.form-control:not(:placeholder-shown)~label {
        transform: scale(.75) translateY(-.5rem) translateX(.25rem);
    }

    .form-control:active,
    .form-control:focus,
    .form-control:hover {
        border-width: 2px;
        border-color: #f9892a;
        border-style: solid;
    }

    .disclaimer {
        font-size: .6em;
        line-height: 1.5;
        text-align: center;
        margin-top: 30px;
    }

    .linkText {
        color: hsla(160, 100%, 37%, 1);
        cursor: pointer;
    }

    @media (min-width: 551px) {
        
    }
</style>