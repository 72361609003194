<script setup>
    import { defineProps, defineEmits } from 'vue'

    defineProps({
        showNetworkMembers: {
            type: Boolean,
            required: true
        }
    })

    const emit = defineEmits(['hideNetwork'])

    const partnersList = [
        "JG Wentworth",
        "Freedom Debt Relief",
        "ClearOne Advantage",
        "Kuber Financial"
    ]

    function hideIfEmpty(event) {
        if (event.srcElement.id === 'networkMembers') {
            emit('hideNetwork')
        }
    }
</script>

<template>
   <transition name="slide-fade" appear v-cloak>
        <div class="modal fade show" id="networkMembers" tabindex="-1" role="dialog" aria-labelledby="networkMembers" aria-hidden="true" v-show="showNetworkMembers" v-cloak @click="hideIfEmpty">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Network Members</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('hideNetwork')"></button>
                    </div>
                    <div class="modal-body">
                        <p><strong>Matched partners included but not limited to:</strong></p>
                        <ul style="font-size: .9em;" id="partnerList">
                            <li v-for="partner in partnersList">
                                {{ partner }}
                            </li>
                        </ul>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="$emit('hideNetwork')">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<style scoped>
    .modal {
        display: unset;
    }

    .modal-body {
        max-height: 500px;
        max-height: 80vh;
        overflow: scroll;
    }
</style>
