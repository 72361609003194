<script setup>
	import { computed, defineProps } from 'vue'
	const myProps = defineProps(['progressPercent'])

	const progressPercentValue = computed(() => {
		return '' + myProps.progressPercent.toString() + '%'
	})
</script>

<template>
	<div class="set-size" id="progress">
	</div>
</template>

<style scoped>
	
	#progress {
		height: 3px;
		background-color: #3C91E6;
		width: v-bind(progressPercentValue);
		transition: all .3s ease-in-out;
	}
	
</style>