<script setup>
	import { defineProps, defineEmits, ref, watch, computed } from 'vue'
    import Chat from '../Chat.vue'
    import Spinner from '../Spinner.vue'
    import axios from "axios"

	const props = defineProps({
		question: {
			type: Object,
			required: true
		},
        firstName: {
            type: String
        }
	})
    const emit = defineEmits(['proceed','hideBackButton','showBackButton'])

    const validEmailTypes = [
        'Verified',
        'Role',
        'Unknown',
        'Catch All',
        'Complainer',
        'Disposable Email',
        'Suppressed'
    ]
    const AC_Invalid = [
        'Unknown',
        'Catch All',
        'Complainer',
        'Disposable Email',
        'Suppressed',
        'Bot',
        'Undeliverable',
        'Malformed',
        'SpamTrap'
    ]
    const displayValidation = ref(false)
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const emailInvalid = ref(false)
    const validating = ref(false)

    watch(validating, async (value) => {
        if (value) {
            emit('hideBackButton')
        } else {
            emit('showBackButton')
        }
    })

    function checkEmail() {
        if (props.question.overrideValidation) {
            if (emailRegex.test(props.question.value)) {
                props.question.overrideValidation = false
                emailInvalid.value = false
            }
        }
    }

    function sendValidations() {
        validating.value = true
        document.getElementById('header').scrollIntoView();

        axios.get('https://api.emailoversight.com/api/emailvalidation?apitoken=62689d38-3ee9-4e5f-9f95-756fcc64f377&listid=44420&email=' + props.question.value,{timeout: 3000}).then(function(response) {
            response = response.data;

            if (validEmailTypes.indexOf(response.Result) === -1) {
                props.question.overrideValidation = true
                emailInvalid.value = true
                displayValidation.value = true
                validating.value = false
                return false;
            }
            if (AC_Invalid.indexOf(response.Result) !== -1) {
                props.question.sendtoAC = false
            }
            displayValidation.value = false
            emailInvalid.value = false
            validating.value = false

            emit('proceed')
        }).catch(function(err) {
            displayValidation.value = false
            emailInvalid.value = false
            validating.value = false

            emit('proceed')
        })
    }

    function onSubmit() {
        let email = props.question.value;
        // perform validations
        if (!email) {
            displayValidation.value = true;
        } else {
            if (!emailRegex.test(email)) {
                emailInvalid.value = true
                props.question.overrideValidation = true
            } else {
                sendValidations()
            }
        }
    }

    let domainName = ref(window.location.host)

    let complianceLink = computed(() => {
        return domainName.value.includes('start.') ? 'firstadvantage.io' : domainName.value
    })
</script>

<template>
    <Spinner v-if="validating" :messages="['Verifying your info...','Reviewing your eligibility...','Checking to see if you qualify...']" />
    <section v-else class="btn-options" :class="{'was-validated' : displayValidation}">
        <p class="questionPreHeading">We're finalizing your relief options!</p>
        <h3 class="questionHeading">What's your email, {{ firstName }}?</h3>

        <div class="form-floating mb-3 mt-4">
            <input 
                type="email"
                class="form-control"
                :class="{'is-invalid': emailInvalid}"
                id="email"
                placeholder="Email"
                required
                v-model="question.value"
                @keyup="checkEmail"
                @keydown.enter="onSubmit">
            <label for="email">Email</label>
            <div class="invalid-feedback">Please provide a valid email</div>
        </div>

        <button class="btn btn-primary" :disabled="!question.validated" @click="onSubmit">Next <i class="fa-solid fa-arrow-right" style="color: #ffffff;"></i></button>

        <p class="disclaimer">By submitting my request and clicking 'Next', I confirm that I have read and agree to the <a :href="'https://' + complianceLink + '/terms-of-use'" target="_blank">Terms & Conditions</a> and <a :href="'https://' + complianceLink + '/privacy-policy'" target="_blank">Privacy Policy</a> of this site.</p>
        <div v-if="question.chat">
            <Chat :message="question.chat" />
        </div>
    </section>
</template>

<style scoped>
    .btn-options {
        min-height: 65vh;
        position: relative;
    }
    .questionHeading {
        font-size: 1.6em;
        font-weight: 700;
        color: #2a2a2b;
        text-align: center;
        line-height: 1.2;
        margin-bottom: 35px;
    }

    .questionPreHeading {
        color: #19329a;
        font-style: italic;
        font-size: .9em;
        font-weight: 600;
        text-align: center;
        margin-bottom: 5px;
    }

    .questionSubHeading {
        font-size: 1em;
        text-align: center;
        margin-bottom: 5px;
    }

    .btn-primary {
        background-color: #0D496F;
        border-color: #0D496F;
        padding: 20px 42px;
        font-size: 1.4em;
        font-weight: 700;
        border-radius: 39px;
        /* position: absolute;
        right: 0;
        bottom: 22px; */
        transition: all .3s ease;
        box-shadow: 0 10px 15px #00ACEF4d;
        display: block;
        width: 100%;
        margin-top: 40px;
    }

    .btn-primary:disabled {
        /* background-color: #ddd;
        border-color: #efefef; */
        box-shadow: none;
    }

    .form-control {
        border-color: #ccc;
        border-radius: 3px;
        box-shadow: 0 3px 0 #dbdBE866;
        position: relative;
        padding-left: 18px;
        height: 70px;
    }

    .form-floating>label {
        padding-left: 20px;
        color: #ccc;
        padding: 1.2rem;
    }

    .form-floating>.form-control:focus~label,
    .form-floating>.form-control:not(:placeholder-shown)~label {
        transform: scale(.75) translateY(-.5rem) translateX(.25rem);
    }

    .form-control:active,
    .form-control:focus,
    .form-control:hover {
        border-width: 2px;
        border-color: #f9892a;
        border-style: solid;
    }

    .disclaimer {
        font-size: .6em;
        line-height: 1.5;
        text-align: center;
        margin-top: 30px;
    }

    @media (min-width: 551px) {
        
    }
</style>